import {FC, useCallback, useEffect, useRef, useState} from "react";
import Webcam from 'react-webcam'
import {Button, Col, Modal, Progress, Row, Spin, Typography} from 'antd'
import {CheckCircleOutlined, RedoOutlined, SwapOutlined} from '@ant-design/icons';
import {KeyboardReact} from "react-simple-keyboard";
import styles from './HomeComponent.module.css';
import "react-simple-keyboard/build/css/index.css";
import logo from './assets/yewpay_logo_negative_primary.png';
import * as faceapi from 'face-api.js';
import {UserService} from "./services/UserService";
import SoundService from "./services/SoundService";


interface HomeComponentProps {

}

const HomeComponent: FC<HomeComponentProps> = (props) => {
    const [loadingLookup, setLoadingLookup] = useState<any>(false);
    const [lookupResult, setLookupResult] = useState<any>(null);
    const [user, setUser] = useState<any>(null);
    const [lookupConfirmed, setLookupConfirmed] = useState<any>(false);
    const [loadingCharge, setLoadingCharge] = useState<any>(false);
    const [capturedImage, setCapturedImage] = useState<string | null>(null);
    const [readyToCharge, setReadyToCharge] = useState<any>(false);
    const [paymentSuccessful, setPaymentSuccessful] = useState<any>(false);
    const [showFlash, setShowFlash] = useState<any>(false);
    const [progressPercentage, setProgressPercentage] = useState<number>(0);
    const [progressInterval, setProgressInterval] = useState<any>(null);
    const [confirmProgressPercentage, setConfirmProgressPercentage] = useState<number>(0);
    const [confirmProgressInterval, setConfirmProgressInterval] = useState<any>(null);
    const [chargeTimeout, setChargeTimeout] = useState<any>(null);
    const keyboardChargeRef = useRef<any>();
    const lookupButtonRef = useRef<any>(null);
    const [chargeInputs, setChargeInputs] = useState<any>({})
    const [focusedChargeInput, setFocusedChargeInput] = useState('amount');
    const [amountStr, setAmountStr] = useState<string>('$0.00');
    const successRefreshSeconds = 15;
    const confirmRefreshSeconds = 10;
    const detection_threshold = 0.9;
    const [devices, setDevices] = useState<any>([]);
    const [deviceId, setDeviceId] = useState<any>();
    let webcamWidth = 1280;
    let webcamHeight = 960;
    const flipWidthHeight = true;
    if (flipWidthHeight) {
        let tmp = webcamWidth;
        webcamWidth = webcamHeight;
        webcamHeight = tmp;
    }
    const keyboardChargeLayout = {
        'default': [
            '{enter}',
            '1 2 3',
            '4 5 6',
            '7 8 9',
            '{bksp} 0 00'
        ]
    }

    const keyboardChargeDisplay = {
        '{bksp}': '<',
        '{enter}': 'Charge'
    }

    const keyboardButtonTheme = [
        {
            class: styles.keyboard_button_default,
            buttons: '1 2 3 4 5 6 7 8 9 0 00 {bksp}'
        },
        {
            class: styles.keyboard_button_action,
            buttons: '{enter}'
        }
    ]

    const keyboardButtonThemeDisabled = [
        {
            class: styles.keyboard_button_default,
            buttons: '1 2 3 4 5 6 7 8 9 0 00 {bksp}'
        },
        {
            class: styles.keyboard_button_action_disabled,
            buttons: '{enter}'
        }
    ]


    const webcamRef = useRef<Webcam | null>(null)

    const fetchAndDisplayDevices = async () => {
        devices.length = 0
        const mediaDevices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = mediaDevices.filter((device: MediaDeviceInfo) => device.kind === 'videoinput');


        if (videoDevices.length) {
            // Joining the device labels to display in a single alert.
            videoDevices.map((device: any) => devices.push(device.deviceId));
            setDeviceId(devices.length > 1 ? devices[1] : devices[0])
        } else {
            alert("No video devices found!");
        }
    };

    const detectFaces = async () => {
        if (!webcamRef.current || !webcamRef.current.video) return;

        const detections = await faceapi.detectAllFaces(
            webcamRef.current.video,
            new faceapi.TinyFaceDetectorOptions()
        );
        if (detections.length > 0) {
            const filteredDetections = detections.filter(detection => detection.score > detection_threshold);
            if (filteredDetections.length > 0) {
                console.log(`Face Detected!`)
                console.log(filteredDetections)
                // A face was detected
                const imageSrc = webcamRef.current.getScreenshot();
                handleFaceLookup(imageSrc, filteredDetections)
            }
        }
    };

    const handleFaceLookup = (imageSrc: any, filteredDetections: any) => {

        const img = new Image();
        img.src = imageSrc;

        img.onload = () => {
            // Check if webcamRef.current or webcamRef.current.video is null
            if (!webcamRef.current || !webcamRef.current.video) {
                return;
            }

            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;

            const ctx: any = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            const scaleX = img.width / webcamRef.current.video.videoWidth;
            const scaleY = img.height / webcamRef.current.video.videoHeight;

            filteredDetections.forEach((detection: any) => {
                const box = {
                    x: detection.box.x * scaleX,
                    y: detection.box.y * scaleY,
                    width: detection.box.width * scaleX,
                    height: detection.box.height * scaleY
                };

                ctx.strokeStyle = '#7ac968'; // color of the box, green for example
                ctx.lineWidth = 4;
                ctx.strokeRect(box.x, box.y, box.width, box.height);

                // ctx.font = '16px Arial';
                // ctx.fillStyle = '#FF0000';
                // ctx.fillText(detection.score.toFixed(3), box.x, box.y > 20 ? box.y - 5 : 20);
            });

            const modifiedImageSrc = canvas.toDataURL();
            setCapturedImage(modifiedImageSrc);
            lookupUser(imageSrc);
        };
    }

    const lookupUser = (imageSrc: any) => {
        setLoadingLookup(true);
        UserService.lookupFace(JSON.stringify({
            'image': imageSrc,
        }), setLoadingLookup).subscribe((data: any) => {
            console.log(`User: ${JSON.stringify(data, null, 2)}`)
            setUser(data);
            handleLookupResult(data?.payment_methods_available);
            SoundService.play(1);
        }, (error: any) => {
            console.error(`Error verifying face: ${error}`);
            try {
                const errorJson = JSON.parse(error)
            } catch (err) {
            }
            handleLookupResult(false);
            setLoadingLookup(false);
        })
    }

    useEffect(() => {
        const loadModels = async () => {
            await faceapi.nets.tinyFaceDetector.loadFromUri(process.env.PUBLIC_URL + '/models');
        };

        loadModels();
        fetchAndDisplayDevices();
        const faceDetectionInterval = setInterval(() => {
            detectFaces();
        }, 1000); // Check every second

// Don't forget to clear the interval when component unmounts:
        return () => {
            clearInterval(faceDetectionInterval);
        };
    }, []);


    const startLookup = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!lookupButtonRef.current) return;

        if (webcamRef.current) {
            setShowFlash(true);
            setTimeout(() => {
                setShowFlash(false);
            }, 200);
            const imageSrc = webcamRef.current.getScreenshot();
            setCapturedImage(imageSrc);
            lookupUser(imageSrc);
        }
    }

    const confirmLookup = () => {
        if (confirmProgressInterval) clearInterval(confirmProgressInterval);
        setLookupConfirmed(true);
    }

    const handleLookupResult = (success: boolean) => {
        console.log(`Lookup result: ${success}`)
        setLookupResult(success ? 'USER_FOUND' : 'NOT_FOUND')
        setReadyToCharge(success)
        let elapsed = 0;
        setConfirmProgressPercentage(100);

        const intervalId = setInterval(() => {
            elapsed += 100; // 100ms
            const percentage = 100 - (elapsed / (confirmRefreshSeconds*1000)) * 100;
            setConfirmProgressPercentage(percentage);

            if (percentage <= 0) {
                clearInterval(intervalId);
                refresh();
            }
        }, 100);

        setConfirmProgressInterval(intervalId);  // Store the interval ID
    }


    const charge = () => {

        setLoadingCharge(true);

        const timeoutId: any = setTimeout(() => {
            setLoadingCharge(false);
            handleChargeResult(true);

        }, 2000);

        setChargeTimeout(timeoutId);  // Store the timeout ID
    }

    const handleChargeResult = (success: boolean) => {
        setPaymentSuccessful(success);

        if (success) {
            SoundService.play(3);
            let elapsed = 0;
            setProgressPercentage(100);

            const intervalId = setInterval(() => {
                elapsed += 100; // 100ms
                const percentage = 100 - (elapsed / (successRefreshSeconds*1000)) * 100;
                setProgressPercentage(percentage);

                if (percentage <= 0) {
                    clearInterval(intervalId);
                    refresh();
                }
            }, 100);

            setProgressInterval(intervalId);  // Store the interval ID
        }
    }


    function refresh() {
        if (chargeTimeout) clearTimeout(chargeTimeout);
        if (progressInterval) clearInterval(progressInterval);
        if (confirmProgressInterval) clearInterval(confirmProgressInterval);

        setLoadingCharge(false);
        setLoadingLookup(false);
        setReadyToCharge(false);
        setCapturedImage(null);
        setPaymentSuccessful(false);
        setLookupResult(null);
        setLookupConfirmed(false);
        setUser(null);

        resetChargeState();
    }

    const resetChargeState = () => {
        if (keyboardChargeRef?.current) {
            keyboardChargeRef.current.clearInput('amount');
            setAmountStr(formatAmount(null));
        }
    }

    const onKeyReleasedCharge = (button: any) => {
        if (button == '{enter}' && canCharge()) {
            if (+getChargeInputValue('amount') >= 10000) {
                Modal.confirm({
                    title: 'Are you sure you want to charge this amount?',
                    content: (<Typography>Charge this
                        customer <b>{formatAmount(getChargeInputValue('amount'))}</b></Typography>),
                    width: '100%',
                    onOk: () => {
                        charge();
                    },
                    onCancel: () => {
                        console.log('Charge canceled.');
                    },
                });
            } else {
                charge();
            }
        } else if (button == '{bksp}') {
            keyboardChargeRef.current.setCaretPosition(getChargeInputValue(focusedChargeInput).length)
            setAmountStr(formatAmount(getChargeInputValue('amount')));
        } else {
            setAmountStr(formatAmount(getChargeInputValue('amount')));
        }
    };

    const getChargeInputValue = (inputName: string) => {
        return chargeInputs[inputName] || "";
    };

    function formatAmount(amountString: any) {
        if (!amountString) {
            return '$0.00'
        }
        const amountInCents = parseInt(amountString, 10);
        const amountInDollars = amountInCents / 100;
        return `$${amountInDollars.toFixed(2)}`;
    }

    const onChangeChargeInput = (event: any) => {
        const inputVal = event.target.value;

        setChargeInputs({
            ...chargeInputs,
            [focusedChargeInput]: inputVal
        });
        if (keyboardChargeRef?.current) {
            keyboardChargeRef.current.setInput(inputVal);
        }
    };

    const onChangeAllCharge = (inputs: any) => {
        setChargeInputs({...inputs});
    };

    function canCharge() {
        return getChargeInputValue('amount') > 0 && !(loadingCharge || getChargeInputValue('amount') < 50 || getChargeInputValue('amount') > 50000 || paymentSuccessful)
    }

    function getTimeLeft(percentProgress: any, maxTime: any) {
        const secondsLeft: any = (100 - percentProgress) / maxTime
        return `${secondsLeft.toFixed(1)}s`
    }

    function changeDevice() {
        if (devices.length < 2) return;
        if (webcamRef.current && webcamRef.current.video && webcamRef.current.video.srcObject) {
            let stream: MediaStream = webcamRef.current.video.srcObject as MediaStream;
            let tracks = stream.getTracks();

            tracks.forEach(track => track.stop());
            webcamRef.current.video.srcObject = null;
        }
        setDeviceId((prevDevice: any) => prevDevice == devices[0] ? devices[1] : devices[0])
    }

    return (
        <div style={{height: '100%', maxHeight: '100%', display: 'flex', flexDirection: 'column', width: '85%'}}>
            <Row style={{
                flex: '0 0 10%',
                maxHeight: '10%',
                overflow: 'auto',
                paddingTop: '20px',
                paddingBottom: '10px'
            }}>
                <Col span={24}>
                    <div style={{overflow: 'auth', display: 'grid', placeItems: 'center'}}>
                        {lookupResult == 'USER_FOUND' && user && user.payment_methods_available &&
                            <Typography.Text style={{fontSize: '24px', fontWeight: '600', color: '#7ac968'}}>
                                CARD ON FILE
                            </Typography.Text>
                        }
                        {lookupResult == 'USER_FOUND' && user && !user.payment_methods_available &&
                            <Typography.Text style={{fontSize: '24px', fontWeight: '600', color: '#eb1c24'}}>
                                NO ELIGIBLE CARD ON FILE
                            </Typography.Text>
                        }
                        {lookupResult == 'NOT_FOUND' && !user &&
                            <Typography.Text style={{fontSize: '24px', fontWeight: '600', color: '#eb1c24'}}>
                                USER NOT FOUND
                            </Typography.Text>
                        }
                    </div>
                </Col>
            </Row>
            <Row style={{
                flex: '0 0 40%',
                maxHeight: '40%',
                overflow: 'auto',
                paddingTop: '10px',
                paddingBottom: '10px'
            }}>
                <Col span={24}>
                    <div style={{backgroundColor: '#000', width: '100%', position: 'relative', borderRadius: '8px'}}>
                        <img src={logo} alt="Logo" style={{position: 'absolute', bottom: '10px', left: '10px', height: '20px', zIndex: 2}}/>
                        {capturedImage ? (
                            <div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: showFlash ? 'rgba(255, 255, 255, 0.9)' : 'transparent',
                                        transition: 'background-color 0.3s ease-out'
                                    }}
                                />
                                <img src={capturedImage} alt="Captured"
                                     style={{width: '100%', objectFit: 'cover', borderRadius: '8px'}}/>
                                {(loadingLookup || loadingCharge) && (
                                    <div style={{
                                        position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
                                        backgroundColor: 'rgba(128, 128, 128, 0.5)', display: 'flex',
                                        justifyContent: 'center', alignItems: 'center'
                                    }}>
                                        <Spin/>
                                    </div>
                                )}
                                {!(loadingLookup || loadingCharge) &&
                                    <div style={{position: 'absolute', right: '20px', top: '20px'}}>
                                        <Button type="primary" shape="circle" icon={<RedoOutlined/>} size={'small'}
                                                onClick={() => refresh()}/>
                                    </div>
                                }
                            </div>
                        ) : (
                            <div>
                                {deviceId &&
                                    <Webcam
                                    audio={false}
                                    screenshotFormat="image/jpeg"
                                    ref={webcamRef}
                                    style={{width: '100%', objectFit: 'cover', borderRadius: '8px'}}
                                    videoConstraints={{
                                    width: {min: webcamWidth*(1/2), ideal: webcamWidth, max: webcamWidth},
                                    height: {min: webcamHeight*(1/2), ideal: webcamHeight, max: webcamHeight},
                                    deviceId: deviceId
                                }}
                                    mirrored={false}
                                    onUserMediaError={error => {
                                    console.error('Webcam Error: ', error)
                                }}
                                    />
                                }
                                {!loadingLookup && devices.length > 1 &&
                                    <div style={{position: 'absolute', right: '20px', top: '20px'}}>
                                        <Button type="primary" shape="circle" icon={<SwapOutlined/>} size={'small'}
                                                onClick={() => changeDevice()}/>
                                    </div>
                                }
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
            <Row style={{
                flex: '0 0 50%',
                maxHeight: '50%',
                overflow: 'auto',
                paddingTop: '10px',
                paddingBottom: '10px'
            }}>
                <Col span={24}>
                    {!readyToCharge && !lookupResult &&
                        <Button ref={lookupButtonRef} style={{fontSize: '24px', height: '48px'}} type={"primary"} block
                                onClick={startLookup} loading={loadingLookup} >Lookup User</Button>
                    }
                    {!readyToCharge && lookupResult == 'NOT_FOUND' &&
                        <Button ref={lookupButtonRef} style={{fontSize: '24px', height: '48px'}} type={"primary"} block
                                onClick={refresh} loading={loadingLookup} >Try Again</Button>
                    }
                    {readyToCharge && !lookupConfirmed &&
                        <Button ref={lookupButtonRef}
                                style={{
                            fontSize: '24px',
                            height: '48px',
                            position: 'relative', // Set the position to relative
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'  // This ensures that the text stays centered
                        }}
                                type={"primary"} block
                        onClick={confirmLookup} loading={loadingLookup} >
                            Confirm
                            <Progress
                                type={'circle'}
                                percent={confirmProgressPercentage}
                                format={(progressPercentage) => getTimeLeft(confirmProgressPercentage, confirmRefreshSeconds)}
                                strokeColor={'white'}
                                size={25}
                                showInfo={false}
                                style={{
                                    position: 'absolute', // Set the position to absolute
                                    right: '20px',        // Position it 20px from the right
                                    top: '50%',           // Start from the middle vertically
                                    transform: 'translateY(-50%)'  // Adjust to perfectly center vertically
                                }}
                            />
                        </Button>
                    }
                    {readyToCharge && lookupConfirmed && !paymentSuccessful &&
                        <KeyboardReact
                            keyboardRef={r => (keyboardChargeRef.current = r)}
                            layout={keyboardChargeLayout} display={{
                            '{bksp}': '<',
                            '{enter}': `Charge ${amountStr}`
                        }}
                            buttonTheme={canCharge() ? keyboardButtonTheme : keyboardButtonThemeDisabled}
                            onKeyReleased={onKeyReleasedCharge}
                            inputName={focusedChargeInput}
                            onChangeAll={onChangeAllCharge}
                        />
                    }
                    {paymentSuccessful &&
                        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                            <Button
                                style={{
                                    fontSize: '24px',
                                    height: '48px',
                                    position: 'relative', // Set the position to relative
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'  // This ensures that the text stays centered
                                }}
                                type={"primary"}
                                block
                                onClick={() => refresh()}
                                loading={loadingCharge}
                            >
                                New Customer
                                <Progress
                                    type={'circle'}
                                    percent={progressPercentage}
                                    format={(progressPercentage) => getTimeLeft(progressPercentage, successRefreshSeconds)}
                                    strokeColor={'white'}
                                    size={25}
                                    showInfo={false}
                                    style={{
                                        position: 'absolute', // Set the position to absolute
                                        right: '20px',        // Position it 20px from the right
                                        top: '50%',           // Start from the middle vertically
                                        transform: 'translateY(-50%)'  // Adjust to perfectly center vertically
                                    }}
                                />
                            </Button>
                            <div style={{
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                textAlign: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%'
                            }}>
                                <CheckCircleOutlined style={{color: '#7ac968', fontSize: '48px', paddingBottom: '20px'}}/>
                                <Typography.Text style={{fontSize: '30px', fontWeight: 'bold', color: '#7ac968'}}>
                                    Payment Approved
                                </Typography.Text>
                            </div>
                        </div>

                    }
                </Col>
            </Row>
        </div>
    )
        ;

}

export default HomeComponent;