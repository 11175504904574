import React from 'react';
import logo from './logo.svg';
import './App.css';
import {ConfigProvider} from 'antd';
import HomeComponent from "./HomeComponent";


function App() {
    return (
        <ConfigProvider theme={{token: {fontFamily: 'Montserrat', colorPrimary: '#7ac968'}}}>
            <div className="App" style={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
                <header className="App-header" style={{ height: '100%', maxHeight: '100%' }}>
                    <HomeComponent />
                </header>
            </div>
        </ConfigProvider>
    );
}

export default App;